<template>
  <task-detail />
</template>

<script>
const TaskDetail = () => import('@/components/Car/TaskDetail')

export default {
  name: 'TaskDetailView',
  components: {
    TaskDetail
  }
}
</script>
